import { environment } from "../../environments/environment";

export class Connect {
  // static urlServerG = 'http://localhost:5000/project-wallnet/us-central1/';
    // static urlServerG = 'https://us-central1-project-wallnet.cloudfunctions.net/';
    // static urlServerLara = 'http://localhost.walldev/api/';
    static urlServerLaraApi = environment.urlserver.laravelApi;
    static urlServerLara = environment.urlserver.laravel;
    //static urlServerG = environment.urlserver.google;
    // max 10 MB
    static sizeFileGeneric = 10000001;
    static str_sizeFileGeneric = '10 MB';
    // max 10 MB
    static sizeFileGenericLarge = 50000001;
    static str_sizeFileGenericLarge = '50 MB';
    // max 50 MB
    static sizeSingoloFileGara = 50000001;
    static str_sizeSingoloFileGara = '50 MB';

    // max 20 MB
    static sizeSingoloFileCommesse = 20000001;
    // max 10 MB da Cassiopea
    static sizeSingoloFileCassiopea = 29000001;
    static str_sizeSingoloFileCassiopea = '29 MB';

    static str_sizeSingoloFileCommesse = '20 MB';
    // max 30 MB
    static sizeSingoloFileCsvRdi = 30000001;
    static str_sizeSingoloFileCsvRdi = '30 MB';
    // max 50 MB
    static sizeSingoloFileMediaRdi = 50000001;
    static str_sizeSingoloFileMediaRdi = '50 MB';
}
