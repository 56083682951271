<h2 mat-dialog-title>{{titolo}}</h2>
<mat-dialog-content class="mb-2 pb-3">
    <form [formGroup]="popupForm">
        <div class="text-left">
            {{testo_info}}
            <div class="">
                <!--<label>Float label: </label>-->
                <mat-radio-group [formControl]="floatLabelControl">
                    <mat-radio-button [value]="valore_1" class="mb-2">
                        <span [innerHtml]="testo_scelta_1"></span>
                    </mat-radio-button>
                    <mat-radio-button [value]="valore_2" class="">
                        <span [innerHtml]="testo_scelta_2"></span>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close cdkFocusInitial [class]="classButton" (click)="chiudi()">Annulla</button>
    <button mat-button [mat-dialog-close]="true" [disabled]="disabled_button" [class]="classButton"
        (click)="vai()">Ok</button>
</mat-dialog-actions>
